.icon-card {
  border-radius: 0;
  border: none;
  background-color: #fff;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  min-width: 120px;
  margin: 0;
  gap: 5px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.16);
width: 8vw;
  &:hover {
    background-color: $app-primary2-hover;
  }


  img{
    width: 50%;
    aspect-ratio: 1/1;
  }
}
.container-icon-cards-decision{
  min-width: calc(max(18vw,246px )) !important;
    max-width: calc(max(27vw,420px )) !important;


}
.square-icon-card-button {
  padding: 2px !important;
  background-color: transparent !important;
  margin: 0;
  border-radius: 2px !important;
  border: none !important;
  width: 100%;
}

.square-icon-card-button .MuiTouchRipple-root * {
  color: rgba($color: $app-primary, $alpha: 0.6) !important;


}

.square-icon-card-button * {

  margin: 0;
  border-radius: 8px !important;
}

.square-icon-buttom * {
  border-radius: 2px !important;
}

.icon-card-i-primary {
  background-color: rgba($color: $app-primary2-light, $alpha: 0.2);
  padding: 4px;
  border-radius: 3px !important;
}

.square-icon-button * {
  border-radius: 2px !important;
}

.icon-card:hover {
  text-decoration: none !important;

  .icon-card-text {

    font-weight: 500;
  }
}

.icon-card .container-icon-card {
  padding-top: 7%;
  @include media-breakpoint-up(xl) {
    padding-top: 12%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  width: 100%;
  height: 100%;
}

.icon-card .icon-card-text {
  color: #000!important;
  font-size: 12px;
  font-weight: 500;
}

.icon-card .icon-card-subtext {
  color: $danger !important;
  font-size: 10px;
  letter-spacing: 0.24em;
}

.icon-card .text-app {
  color: $app-primary2-light !important;
}

.expansion-panel {
  .MuiExpansionPanelSummary {
    &-root {
      background-color: $app-primary;
      color: white;
      min-height: 40px !important;
      border-radius: 5px;
    }

    &-content {
      margin: 0 !important;
    }

    &-expandIcon {
      color: white;
    }
  }
}

.MuiExpansionPanel-root {
  box-shadow: none !important;

  .MuiCollapse-container {
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    border-bottom: $jr-border;
    border-left: $jr-border;
    border-right: $jr-border;
  }
}