.square-icon-buttom,
.square-icon-button {
  margin: 0;
  max-height: 32px !important;
  padding: 7px !important;
  border-radius: 2px !important;
}

.square-icon-buttom *,
.square-icon-button * {
  border-radius: 2px !important;
}

.square-icon-button-primary2,
.square-icon-button-primary,
.square-icon-button-danger {
  margin: 0;
  max-height: 32px !important;
  padding: 7px !important;
  border-radius: 2px !important;

  &.square-icon-button-danger {
    background-color: rgba($color: $danger, $alpha: 0.4) !important;
    color: $danger;
    &* {
      color: $danger !important;
    }
    &.Mui-disabled {
      background-color: $app-light !important;
      color: $app-dark;
      &* {
        color: $app-dark !important;
        border-radius: 2px !important;
      }
    }
  }
  &.square-icon-button-primary {
    background-color: rgba($color: $app-primary, $alpha: 0.3) !important;
    color: $app-primary;
    &* {
      color: $app-primary !important;
    }
    &.Mui-disabled {
      background-color: $app-light !important;
      color: $app-dark;
      &* {
        color: $app-dark !important;
        border-radius: 2px !important;
      }
    }
  }
  &.square-icon-button-primary2 {
    background-color: $app-primary2-light-plus !important;
    color: $app-primary2-light;
    &* {
      color: $app-primary2-light !important;
      border-radius: 2px !important;
    }
  }
  &.Mui-disabled {
    opacity: 0.3;
  }
}
