.kotler-stepper-container{

    border-bottom: 1px solid $app-light;
    margin: 5px 0px;
}
.kotler-stepper {
    overflow-x: auto;
    padding: 0px 0px !important;
    margin: 0px;


    .MuiStepConnector-lineHorizontal {
        background-color: transparent;
        border: none;
    }

    .MuiStepIcon-text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
    }

    .MuiStepLabel-label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: $app-dark;
        padding-top: 10px;
        padding-bottom: 10px;
    }

 
    
    .MuiStepLabel-iconContainer{
        padding: 10px 0;
        border-bottom: 3px solid transparent;
        margin-right: 5px;
    
    }
    svg {
        fill: transparent;
        stroke: transparent;
        background-color: $app-light;
        border-radius: 2px;
        transition: all ease-in .3s;
        width: 24px;
        height: 24px;
    }
    .MuiStepIcon-text{
        fill: $app-dark2;
    }
    .MuiStep-completed{
        svg {
            background-color: $app-primary2;
        }
        .MuiStepIcon-text{
            fill: #fff;
        }
    }
    .MuiStepLabel-root.active {
        .MuiStepIcon-text{
            fill: $app-primary;
        }
        .MuiStepLabel-iconContainer{
          border-bottom-color: $app-primary;
          svg{
            background-color: $app-primary-light;
          }
        }
    }
}