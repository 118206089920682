.app-subheader,
.student-subheader {
  z-index: 1000 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.subheader-badge {
  background-color: $app-primary2;
  font-size: 11px;
}
.subheader-badge-danger {
  background-color: $danger;
  font-size: 11px;
}
.header-notifications .app-notification .dropdown-menu {
  width: calculateRem(350px);
  left: 0 !important;
  right: auto !important;

  &:after,
  &:before {
    left: 8px;
  }
}

.fixed-sidebar {
  min-height: calc(100vh - 168px);
}

.page-heading {
  min-height: calculateRem(70px);
}

.custom-fixed-sidebar {
  font-size: 14px;

  .Mui-selected {
    background-color: $gray-1 !important;
    border-radius: 5px;
  }

  .MuiListItem-root {
    color: rgba(0, 0, 0, 0.6);
    flex: 1;
    width: auto;
    margin-left: 5px;
    margin-right: 5px;

    &:hover {
      background-color: unset !important;
      color: rgba(0, 0, 0, 0.7);

      &.Mui-selected {
        background-color: $gray-1 !important;
      }
    }
  }
}
