.go-to-ai-btn {
  background-color: var(--primary2-light) !important;
}
.go-to-ai-btn-hover .MuiTooltip-tooltip {
  background-color: var(--primary2-light);
  box-shadow: 1px 2px 3px #0000005d;
  color: var(--dark);
  font-size: 10px;
  font-weight: 500;
  border-radius: 50px;
  padding: 5px 8px;
}
