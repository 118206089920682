.kotler-switch-form-control{
    .MuiFormControlLabel-root{
column-gap: 5px;
        margin: 0;
    }
}
.kotler-switch {


    &.MuiSwitch-root {
        width: 48px !important;
        height: 28px !important;
        padding: 0 !important;
    }

    .MuiSwitch-input {
        left: -100%;
        width: 300%;
    }

    .MuiButtonBase-root {
        padding: 0;

        display: flex;
        align-items: center;

        width: 28px;
        height: 100%;
    }

    .MuiSwitch-track {
        border-radius: 16px;
        background-color: $base-white !important;
        border: 1.5px solid $app-dark2;
    }

    .MuiIconButton-label {
        margin: 0;
    }

    .MuiSwitch-thumb {
        margin: 0px;
        width: 24px;
        height: 24px;
        background-color: $app-dark;
        transition: background .5s ease-in-out;
        box-shadow: none;
    }

    .MuiSwitch-switchBase.Mui-checked {

        transform: translateX(20px);

        .MuiSwitch-thumb {

            background-color: $app-primary2-light;
        }
    }

    .MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track {
        opacity: 1 !important;
    }

    .MuiSwitch-switchBase.Mui-disabled {

        .MuiSwitch-thumb {

            background-color: $app-dark2 !important;
        }
    }
}