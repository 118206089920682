
.kotler-drop-down-menu{
    border-radius: 0;
    border: none;
    margin-top: 10px;
    min-width: 280px;
    padding: 0;
    filter: drop-shadow(0px 2px 4px rgba(51, 63, 72, 0.64));
}
.kotler-drop-down-menu .dropdown-item{
min-height: 50px;
height: 50px;
display: flex;
align-items: center;
}
.kotler-drop-down-menu * {
    font-weight: 500;
    color: $app-dark;
}
.kotler-drop-down-menu .jr-link:hover{
    background-color: $app-light;
    font-weight: 500;
}
.kotler-drop-down-menu .kotler-icon{
    width: 16px;
    
  transition: transform .2s ease-in-out;
    height: 16px;
    stroke: $app-dark;
    margin-right: 10px;
}
.kotler-drop-down-menu .dropdown-item-danger:hover{
    background-color: rgba($color: $danger, $alpha: 0.2);
}
.kotler-drop-down-menu .dropdown-item-danger  * {
    color: $danger;
    stroke: $danger;
}
.kotler-drop-down-menu-header, .kotler-drop-down-menu-header *{
    background-color: $app-dark;
    color: $white;
}
.kotler-drop-down-menu 
.dropdown-item{
    &.active-option  * {
        color: $app-primary2-light ;
        font-weight: 700;
    }
    &.active-option-dark *{
        color: $app-primary2 ;
        font-weight: 700;
    }
    .dropdown-item-subtext * {
        color: $app-primary2;
    }
}