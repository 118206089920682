.MuiAppBar-root.MuiPaper-root {
  box-shadow: 0 3px 10px -3px rgba(0, 0, 0, 0.2);
}

.green .side-nav {
  ul.nav-menu > li > button:hover,
  ul.nav-menu > li > button:focus,
  ul.nav-menu > li > a.active,
  ul.nav-menu > li > a:hover,
  ul.nav-menu > li > a:focus {
    background-color: #fff;
    color: $app-primary;
  }

  .bg-primary {
    background-color: $app-primary !important;
  }
}
