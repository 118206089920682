.principal-carrousel::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  margin-top: 10px;
}
.principal-carrousel::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
  box-shadow: 0 0 1px 1px #bbb, inset 0 0 7px rgba(0, 0, 0, 0.3);
}
.principal-carrousel::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(to left, #333f48, #009681);
  box-shadow: inset 0 0 1px 1px #5c6670;
}
.principal-carrousel::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to left, #8391a6, #009681);
}
.principal-carrousel {
  min-width: 100%;
  padding: 3px 3px;
  margin: 0;
  overflow: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
