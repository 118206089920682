/* .cropper-view-box,
.cropper-face {
  border-radius: 50%;
} */
.canvas {
  width: 200px;
  max-height: 200px;
}
.profile-dropzone-file-btn {
  outline: none;
  cursor: pointer;
}
