@mixin scroll_live_news() {
  &::-webkit-scrollbar {
    width: 4.5px;
    height: 4px;
    margin-top: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: 0 0 1px 1px #bbb, inset 0 0 7px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background: linear-gradient(
      to left,
      $app-primary2-light,
      $app-primary2-light
    );
    border-radius: 15px;
    // box-shadow: inset 0 0 1px 1px $app-primary2;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(
      to left,
      $app-primary2-light,
      $app-primary2-light
    );
  }
}

.kotler-live-news-tablet {
  aspect-ratio: 10/7.5;
  width: 100%;

  position: relative;
  .btn-physical {
    content: "";

    // width: 0.5%;
    // height: 6.5%;
    position: absolute;
    left: 95.2%;
    bottom: 21.3%;
    width: 0.8%;
    height: 6.4%;
    background-color: rgba(186, 186, 186, 1);
    shape-outside: polygon(0 100%, 0 100%, 80% 90%, 80% 10%, 0 0);
    clip-path: polygon(0 100%, 0 100%, 80% 90%, 80% 10%, 0 0);
    z-index: 100;
    transition: all ease-in 0.15s;
    &:hover {
      cursor: pointer;
    }
    &:active {
      width: 0.5%;
    }
  }
  .tablet-case {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    pointer-events: none;
    border-radius: 10px;
    position: absolute;
    bottom: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 10;
  }
  .display {
    * {
      @include scroll_live_news();
    }
    position: absolute;
    bottom: 9.4%;
    top: 4.4%;
    left: 7.1%;
    right: 7.1%;
    border: none;
    background-color: transparent;
    border-radius: 10px;
    background-color: white;
    display: flex;
    padding: 0.3% 0.35% 0.3% 0.4%;
    overflow-x: hidden;
    .display-off-screen {
      $tablet-shadow: 1px 0px 4px 5px rgb(0, 0, 0) inset;
      box-shadow: $tablet-shadow;
      -webkit-box-shadow: $tablet-shadow;
      -moz-box-shadow: $tablet-shadow;
      content: "";
      pointer-events: none;
      border-radius: 10px;
      position: absolute;
      z-index: 1000;
      bottom: 0px;
      top: 0px;
      left: 0px;
      right: 0px;
      z-index: 9;
      overflow: hidden;
background-color: black;
      transition: opacity ease-in-out 1s;
      opacity: 0;
    }
    &.display-off {
      cursor: pointer;
      .display-off-screen  {
        pointer-events: visibleFill;
        opacity: 1;
      }
    }
    .selected-news {
      border-radius: 10px 0 0 10px;
      height: 100%;
      flex: 1;
      z-index: 1;
      overflow: hidden;
    }
    .news-list-container {
      overflow: hidden;
      z-index: 3;
      border-radius: 0 10px 10px 0px;
      box-shadow: -3px 0px 4px 0px rgba(0, 0, 0, 0.3);
      height: 100%;

      width: 0;
      transition: all ease 1s;
      overflow-x: hidden;
      .kotler-live-news-list {
        right: -28%;
        transition: all ease 1s;
      }
      &.show {
        width: 28%;
        .kotler-live-news-list {
          right: 0%;
        }
      }
    }
  }
}
.kotler-live-news-list {
  position: absolute !important;
  bottom: 0%;
  right: 0%;
  top: 0%;
  width: 28%;
  background-color: transparent;
  overflow: auto;
  height: 100%;
  .MuiListItem-root {
    padding: 8px 10px;
    .title {
      width: 100%;
      margin-bottom: 4px;
      text-transform: uppercase;
      color: $app-secondary-main;
      font-size: 13px;
    }
    .MuiListItemText-root {
      background-color: inherit;
      color: $app-dark2;
      .MuiTypography-root {
        font-size: 12px;
        color: inherit;
        display: -webkit-box;
        text-align: justify;
        width: 100%;
        height: 100%;
        // -webkit-line-clamp: 4;
        // -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    &:hover {
      .MuiListItemText-root {
        background-color: transparent;
        text-decoration: underline;
        text-decoration-color: $app-dark;
        color: $app-dark;
      }
    }
    &.Mui-selected {
      .title {
        font-weight: 500;
      }
      background-color: rgba(
        $color: $app-primary-light,
        $alpha: 0.1
      ) !important;
      box-shadow: 0px 2px 0px 0px rgba($color: $app-primary, $alpha: 0.7);
      .MuiListItemText-root {
        background-color: transparent !important;
        text-decoration: none !important;
        font-weight: 500;
        color: inherit !important;
      }
    }
  }
}
.live-news-article {
  display: flex;
  height: 100%;
  width: 100%;

  flex-direction: column;
  .notice-app-bar {
    z-index: 2 !important;
    .MuiContainer-root {
      padding: 2px 0;
      position: relative;
      .live-news-button-show {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -15px;
      }
    }
  }
  .skills-bar {
    box-shadow: none;
    border-top: solid 1px rgba($color: $app-dark, $alpha: 0.1);
    border-bottom: solid 1px rgba($color: $app-dark, $alpha: 0.2);
    z-index: 1 !important;
    padding: 4px 0;
    * {
      color: $app-dark2;
    }
  }
  .notice-container {
    flex: 1;
    height: 0;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    .footer-chatgpt{
      color: $app-dark2 !important;
      text-align: end;
      margin: 2px 0;
      margin-right: 15px;
     
      font-size: 12px !important;
      font-family: "Open Sans", sans-serif;
      font-style: italic;
      font-weight: 500 !important;
      *{
        font-family: "Open Sans", sans-serif;
        color: $app-dark2 !important;
        font-weight: 500 !important;
      }
    }
  }
}
