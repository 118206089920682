.app-dashboard-container {
  position: relative;
  max-width: calculateRem(950px);
  margin-top: calculateRem(50px);

  @include media-breakpoint-up(md) {
    margin: calc((100vh / 3) - 120px) auto 0;
  }

  .jr-card {
    max-width: calculateRem(215px);

    .cbg-dark:hover {
      background-color: $app-primary;
    }

    .cbg-light:hover {
      background-color: $app-primary;

      .text-app {
        color: white !important;
      }
    }

    .cbg-light:hover {
      background-color: $app-primary;

      .text-analitycs {
        color: white !important;
      }
    }

    .cbg-crm:hover {
      background-color: $danger;

      .text-crm {
        color: white !important;
      }
    }

    .vertical-crm {
      background-color: $danger;

      .vertical-crm-text {
        writing-mode: vertical-lr;
        text-orientation: upright;
        font-weight: $font-weight-medium;
      }
    }

    .vertical-crm:hover {
      background-color: white;

      .vertical-crm-text {
        color: $danger !important;
      }
    }
  }
}

//Menu Color
.text-app {
  color: $app-primary2-light !important;
}

.text-crm {
  color: $danger !important;
}

.text-analitycs {
  color: $danger !important;
}

.text-analitycs:hover {
  color: white !important;
}

.app-user-dashboard-container {
  max-width: unset;
  background-size: "cover";
  background-position: left bottom;
  @include media-breakpoint-up(md) {
    margin: calculateRem(50px) auto 0;
    background-size: "cover";
    background-position: center;
  }
}

.container-dashboard-options {
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 95px;
  font-size: 12px;
  margin-bottom: 40px;
  .information-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    .list-information {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .decision-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media-breakpoint-down(sm) {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .list-decision {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
    }
  }

  .dashboard-phone-container {
    width: 18vw;
    min-width: 200px;
  }

  .list-icon-cards {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    justify-content: flex-end;
    text-align: end;
  }

  .list-decision {
    max-width: calc(max(27vw, 420px)) !important;
  }
  @include media-breakpoint-only(sm) {
    margin: 0;
    margin-top: 60px;
    width: 50%;
    margin-left: 50%;

    align-items: center;
    justify-content: center;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    .dashboard-phone-container {
      align-self: center;
    }
    .list-decision {
      grid-template-columns: repeat(3, 1fr) !important;
      &.now {
        grid-template-columns: repeat(2, 1fr) !important;
      }
    }

    @include media-breakpoint-only(md) {
      margin: 0;
      margin-left: 0%;
      margin-right: 0%;
      justify-content: center;
      column-gap: 40px;
      width: 100%;
    }
  }

  @include media-breakpoint-up(lg) {
    justify-content: space-evenly;
    width: 78%;
    margin: 0;
    margin-left: 17%;
    margin-right: 4%;
    flex-wrap: wrap;

    .icon-card-section-title {
      font-size: 20px !important;
    }

    .icon-card .icon-card-text {
      font-size: 14px;
    }

    .icon-card .icon-card-subtext {
      color: $danger !important;
      font-size: 12px;
      letter-spacing: 0.24em;
    }
  }

  @include media-breakpoint-up(xl) {
    margin-left: 20%;

    flex-wrap: wrap;
    width: auto;
    margin-top: 10vh;

    .list-information {
      max-width: calc(max(23vw, 280px)) !important;
    }

    .icon-card-section-title {
      font-size: 20px !important;
    }

    .list-decision {
      margin: 0 2vw;
      max-width: calc(max(28vw, 420px)) !important;
    }

    .icon-card .icon-card-text {
      font-size: 16px;
    }

    .icon-card .icon-card-subtext {
      color: $danger !important;
      font-size: 12px;
      letter-spacing: 0.24em;
    }

    .card-phone-notification {
      .MuiCardHeader-title {
        span {
          font-size: 16px !important;
          line-height: normal !important;
        }
      }

      .MuiCardHeader-subheader {
        p {
          font-size: 14px !important;
          line-height: normal !important;
        }
      }
    }
  }
}

.container-dashboard-options-professor {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  font-size: 12px;

  .dashboard-phone-container {
    width: 18vw;
    min-width: 200px;
  }

  .list-icon-cards {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    justify-content: center;
  }

  .list-information,
  .container-activity-decision {
    max-width: calc(max(18vw, 280px)) !important;
  }

  @include media-breakpoint-only(sm) {
    width: 50%;
    margin-left: 50%;
    align-items: center;
  }
  @include media-breakpoint-up(md) {
    align-items: center;
    width: 90%;
    margin-left: 10%;
    flex-direction: row;
    justify-content: center;

    .container-activity-decision {
      margin: 0 4vw 0 3vw;
    }
  }

  @include media-breakpoint-up(lg) {
    justify-content: center;
    width: 75%;
    margin-left: 15%;
    flex-wrap: wrap;

    .icon-card-section-title {
      font-size: 20px !important;
    }

    .icon-card .icon-card-text {
      font-size: 14px;
    }

    .icon-card .icon-card-subtext {
      color: $danger !important;
      font-size: 12px;
      letter-spacing: 0.24em;
    }
  }

  @include media-breakpoint-up(xl) {
    margin-left: 15%;
    flex-wrap: wrap;
    width: 80%;
    margin-top: 13vh;

    .list-information,
    .container-activity-decision {
      max-width: calc(max(23vw, 280px)) !important;
    }

    .icon-card-section-title {
      font-size: 20px !important;
    }

    .container-activity-decision {
      margin: 0 4vw 0 1vw;
    }

    .icon-card .icon-card-text {
      font-size: 16px;
    }

    .icon-card .icon-card-subtext {
      color: $danger !important;
      font-size: 12px;
      letter-spacing: 0.24em;
    }

    .kotlerHour {
      font-size: 30px;
    }

    .card-phone-notification {
      .MuiCardHeader-title {
        span {
          font-size: 20px !important;
          line-height: normal !important;
        }
      }

      .MuiCardHeader-subheader {
        p {
          font-size: 18px !important;
          line-height: normal !important;
        }
      }
    }
  }
}

.container-dashboard-options-admin {
  .icon-card {
    transition: transform ease-in-out 0.3s;

    &:hover {
      background-color: rgba($color: #fff, $alpha: 1);
      transform: scale(1.1);
    }

    .text-app {
      color: $app-text-dark !important;
    }
  }

  .icon-card-section-title {
    font-size: 20px !important;
  }

  @include media-breakpoint-up(xl) {
    .icon-card-section-title {
      font-size: 24px !important;
    }

    .icon-card .icon-card-text {
      font-size: 20px;
    }

    .icon-card .icon-card-subtext {
      color: $danger !important;
      font-size: 12px;
      letter-spacing: 0.24em;
    }
  }
}
.ticker-container {
  position: sticky;
  z-index: 2000;
  bottom: 0;
  left: 0;
  right: 0;
}
