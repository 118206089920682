/*Footer Styles*/

.app-footer {
  padding: 0 18px;
  @include display-flex();
  @include align-items(center);
  @include justify-content(flex-end);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;

  font-family: $font-family-footer;
  color: $base-white;
  background-color: transparent;

  .jr-link {
    color: $light-gray;
    padding: 0 10px;

    &:not(:last-child) {
      border-right: 1px solid $light-gray;
    }
    &:hover {
      color: $light-gray;
    }
  }

  @media screen and (max-width: 400px) {
    font-size: 12px;
    padding: 0 0px;
  }
}
