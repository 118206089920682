.kotler-select-form-control{
    label{
        font-size: 12px;
        color: $app-dark;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 5px;
        margin-left: 2px;
    }
}
.kotler-select {
    * {
        border: none;
    }

    .kotler-select__control {
        cursor: text;
        background-color: $base-white;
        border-radius: 2px;
        border: 1px solid $app-primary2;

        box-shadow: none !important;
        .kotler-select__single-value{
            color: $app-primary2;
            line-height: 22px;
            font-size: 14px;
        }
        .kotler-select__indicator{
            cursor: pointer;
        }
        &.kotler-select__control--is-disabled {
            border: 1px solid $app-light !important;
            background-color: $app-light;
            cursor: auto;
        }

        &:hover {
            border: 1.8px solid $app-primary2-light;
        }

        &.kotler-select__control--menu-is-open {
            border: 2px solid  $app-primary2;
        }
    }
    .kotler-select__multi-value{
        background-color: $app-primary;
        color: #fff;
        .kotler-select__multi-value__label{
            color: #fff;  
            font-weight: 500;
        }
        .kotler-select__multi-value__remove:hover{
            background-color: rgba($color: #fff, $alpha: 0.2);
            cursor: pointer;
            color: #fff;
        }
    }

    .kotler-select__indicator-separator {
        display: none;
    }

    .kotler-select__indicator {
        padding: 0px 5px !important;
    }

    .kotler-select__menu {
        border-radius: 2px;
        border-color: $base-black;

        >.kotler-select__menu-list {
            padding: 0;

            .kotler-select__option {
                background-color: white !important;
&.kotler-select__option--is-selected{
    color: $app-primary2 !important;
}
                &:hover {
                    background-color: $app-light !important;
                }
            }

        }
    }

}