$cdark: #4A4A4A;
$cprimary-dark: #003594;
$cgreen: #7FB000;
$cblue: #4472C4;
$corange: #ED7C31;
$lwhite: #FFFFFF;

$gray-1: #f6f9fc;
$gray-2: #8898aa;
$gray-3: #E5E5E5;
$gray-4: #f8f9fe;
$gray-5: #E9ECEF;
$gray-6: #8898AA;

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

body {
  background-color: $base-white;
}
