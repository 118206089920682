.crm-form {
  .MuiInputBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.87);
  }

  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: solid;
  }

  .MuiFormControlLabel-label.Mui-disabled {
    color: rgba(0, 0, 0, 0.54);
  }

  .custom-select__control--is-disabled {
    background-color: white;
  }

  .custom-select__single-value--is-disabled {
    color: currentColor;
  }
}

.card-internet {
  .jr-card-body {
    min-height: 500px;
    padding-bottom: 0 !important;
  }

  .jr-card-footer {
    padding-top: 0 !important;
  }
}
