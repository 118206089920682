.kotler-table-principal-container {
  margin-top: 0px;

}

.kotler-table-container {
  display: flex;
  overflow-x: auto;
  padding-top: 16px;
  margin-bottom: 40px;
}

.kotler-table-title-container {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 5px;
}

.kotler-table-title-icon {
  background-color: $app-primary2-light-plus;
  display: inline-block;
  padding: 7px;
  border-radius: 2px;
}

.kotler-table-title-icon-img {
  background-color: $app-primary2-light-plus;
  display: inline-block;
  padding: 0px;
  border-radius: 2px;
  size: 32px;
}

.kotler-table-title-icon svg {
  stroke: $app-primary2-light !important;
  width: 18px !important;
  height: 18px;
}

.kotler-table-title {
  font-weight: 600 !important;
  line-height: 27px !important;
  font-size: 18px;
}

.kotler-table .MuiTableHead-root th {
  font-weight: 600;
  color: $app-dark;
  font-size: 12px;
  vertical-align: bottom;
  padding: 0px 10px 6px 10px;

  &:first-child {
    padding-left: 16px;
  }

  &:last-child {
    padding-right: 16px;
  }

  &.MuiTableCell-paddingNone {
    padding: 0;
  }
}

.kotler-table {


  .MuiTableBody-root td {
    font-weight: 500;
    color: $base-black;
    font-size: 14px;
    line-height: 27px;
    padding: 6px 10px 6px 10px;

    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
    }

    &.MuiTableCell-paddingNone {
      padding: 0;
    }

  }


}

.kt-field-gray,
.kt-field-light-gray {
  min-width: 116px;
  width: 116px;
  max-width: 116px;
}

.kotler-table .kt-field-light-gray,
.kotler-table .kt-field-gray {

  &.MuiInputBase-root,
  .MuiFormControl-root {
    min-width: 116px;
    width: 116px;
    max-width: 116px;
  }
}

.kotler-table .separator {
  border: none;
  width: 2vw;
}

.kotler-table .kt-field-light-gray input,
.kotler-table .kt-field-gray input {
  border-radius: 2px;
  height: 40px;
  font-family: $font-family-base !important;
  padding: 0px 16px 0px 16px;
  font-family: "Poppins", "sans-serif";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  border-width: 1px;
  letter-spacing: 1px;
}

.kotler-table .kt-field-light-gray input:disabled,
.kotler-table .kt-field-gray input:disabled {
  opacity: 0.6;
}

//field light gray
.kotler-table .kt-field-light-gray input {
  background-color: $app-light;
  border-color: $app-dark;
  color: $app-dark;
}


.kotler-table .kt-field-light-gray .MuiOutlinedInput-notchedOutline {
  border-radius: 2px;
  border-width: 1px;
  border-color: $app-dark !important;
}

//field gray 
.kotler-table .kt-field-gray input {
  background-color: $app-dark2;
  border-color: $app-dark2;
  color: $base-white;
}

.kotler-table .kt-field-gray input:disabled {
  opacity: 0.6;
}

.kotler-table {
  .kt-field-gray .MuiOutlinedInput-notchedOutline {
    border-radius: 2px;
    border-width: 1px;
    border-color: $app-dark2 !important;
  }

  .subtitle,
  .subtitle * {
    font-weight: 600 !important;
    color: $app-primary2 !important;
  }

}


.kotler-table-info-details {

  td,
  th {
    border: none;
    font-style: normal;
    font-weight: 600;
  }



  th,
  th * {
    line-height: 27px;
    font-size: 18px;
  }

  td {

    padding-left: 4px;

    * {
      line-height: 18px;
      font-size: 12px;

    }
  }

  td+td,
  td+td * {
    font-weight: 500;
    font-size: 14px;
    color: $base-black;
    &.fa{
      font-weight: 900;
    }
  }

  .subtitle,
  .subtitle * {
    font-weight: 600 !important;
    color: $app-primary2 !important;
  }

  .subtitle-dark,
  .subtitle-dark * {
    font-weight: 600 !important;
    font-size: 16px;
    color: $app-dark !important;
  }

  &.kotler-table-info-sm {
    td {
      padding-top: 5px;
      padding-bottom: 6px;
    }
  }
  &.kotler-table-info-md{
  
  
    td,
    td * {
      font-size: 14px;
    }
  }
}


.kotler-async-table {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;


  .rt-table {

    box-shadow: none !important;
    border: none !important;

    background-color: transparent !important;

    .rt-tr {
      background-color: transparent !important;
      .rt-th{
        &.-sort-desc {
          color: $app-primary;
          font-weight: 700;
        }
        &.-sort-asc {
          color: $app-primary;
          font-weight: 600;
        }
      }
      
      &:hover {
        .rt-td {
      


            color: $app-primary2;
        }
      }

      .rt-th,
      .rt-td {
        box-shadow: none !important;
        border: none !important;
        background-color: white !important;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid $app-light !important;
        padding: auto;

      }
    }

    .rt-th {
      font-weight: 600;
      color: $app-dark;
      font-size: 12px;
    }

    .rt-td {
      font-weight: 500;
      color: $base-black;
      font-size: 14px;
      line-height: 27px;
    }

  }

  .pagination {
    border: none !important;
    box-shadow: none !important;
  }
}

.kotler-table-profit-and-lost {

  td,
  th {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
}