.kotler-time-picker{
    .rc-time-picker-input{
        min-width: 75px;
        border-radius: 2px;
        border: 1px solid $app-primary2;
        height: 40px;
        padding: 0px 16px 0 10px ;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 1px;
        background-color: $base-white;
        color: $app-primary2;
        cursor: pointer;
        &:disabled{
            background-color: $app-light;
            border: 1px solid $app-light;
        }
    }
    .rc-time-picker-clear{
        color:$danger !important;
        top: 50%;
        right: 4px;
        margin-top:-10px;
    } 

}
.rc-time-picker-panel{
    .rc-time-picker-panel-input{

    color:$app-primary2;
    }
    color:$app-primary2;
    .rc-time-picker-panel-inner{
        border: 1px solid $app-primary2;
    }
    .rc-time-picker-panel-select-option-selected{
        background-color: $app-primary2-light-plus !important;
        color:$app-primary2;
    }
}
