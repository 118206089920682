.kotler-header-section-baseline {
  height: 190px;
  min-height: 190px;
  @include media-breakpoint-up(sm) {
    min-height: 183px;
    height: 183px;
    width: 100%;
    overflow-x: hidden;
  }
}
.kotler-header-section {
  height: 200px;
  min-height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: 20px;
  .kotler-header-section-container-buttons {
    display: flex;
    gap: 10px;
  }
  .MuiButtonBase-root {
    font-size: 0.87rem;
    text-transform: none;
    font-weight: 500;
    color: $base-white;
  }
  .kt-btn-submit {
    background-color: $app-primary;
    border-radius: 2px;
  }
  .MuiButtonBase-root:hover {
    transition: all ease-in-out 0.3s;
    filter: drop-shadow(0px 4px 8px rgba(51, 63, 72, 0.64));
  }
  .kt-btn-submit:hover {
    background-color: rgba($color: $app-primary, $alpha: 0.9);
  }
  .kt-btn-cancel {
    background-color: $danger;
    border-radius: 2px;
    min-width: 115px;
  }
  .kt-btn-cancel:hover {
    background-color: rgba($color: $danger, $alpha: 0.8);
  }
  .kotler-badge {
    background-color: $app-primary2-light;
    font-size: 0.7rem;
    padding: 4px 8px;
    color: $app-dark;
    margin: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
  }
}

.kotler-header-section-title {
  font-weight: 700 !important;
}
