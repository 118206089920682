.feature-badge {
  padding: 0px 0px;
  font-size: 10px;
  font-weight: 500;
  background-color: var(--dark);
  min-width: 80px;
  &.new {
    background-color: var(--primary2-light);
  }
  &.fixed {
    background-color: var(--info);
  }
  &.enhancement {
    background-color: var(--warning);
  }
}
