.kotler-text-field-label {
  font-size: 12px;
  color: $app-dark;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px;
  margin-left: 2px;
}
.kotler-text-field-formControl,
.kotler-text-field-form-control,
.kotler-text-field-form-control-gray {
  label {
    font-size: 12px;
    color: $app-dark;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 5px;
    margin-left: 2px;
  }

}

.kotler-text-field,
.kotler-text-field-gray,
.kotler-text-field-light-gray,
.kotler-text-field-primary2 {
  min-height: 40px;

  .MuiInputBase-root {
    padding: 0px 0px 0px 0px;
    min-height: 40px;

    background-color: $app-light;

    .MuiInputBase-input,
    input {
      min-width: 75px;
      border-radius: 2px;
      height: 40px;

      padding: 0px 16px 0px 16px;

      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      border-width: 1px;
      letter-spacing: 1px;
      background-color: $app-light;
      border-color: $app-dark !important;
      color: $app-dark;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 2px;
    border-width: 1px;
    border-color: $app-primary2 !important;
  }

  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: $app-primary2-light !important;
    }
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: $app-primary2 !important;
    }
  }

  .Mui-disabled {
    input {
      background-color: rgba($color: $app-light, $alpha: 0.5);
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $app-light !important;
    }
  }

  &.kotler-text-field-primary2 {

    .MuiInputBase-input,
    input,
    .MuiInputBase-root {
      background-color: #fff;
    }

    input {
      background-color: #fff;
      color: $app-primary2 !important;
      font-weight: 600 !important;
    }
    .Mui-disabled {
      .MuiInputBase-root {
        background-color: $app-light;
      }
      input {
        background-color: $app-light;
      }
  
      .MuiOutlinedInput-notchedOutline {
        border-color: $app-light !important;
      }
    }
  }
}

.kotler-text-field-light-gray {
  .MuiOutlinedInput-notchedOutline {
    border-color: #dddfdd !important;
  }


  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: $app-dark2 !important;
    }
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: $app-dark2 !important;
    }
  }

  input {
    background-color: rgba($color: $app-light, $alpha: 0.5);
    border-color: #dddfdd !important;
    color: $app-dark;
  }
}

.kotler-text-field-gray {
  .MuiInputBase-root {

    background-color: $app-dark2 !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $app-dark2 !important;
    border-radius: 2px;

  }

  input {
    background-color: $app-dark2 !important;
    border-color: $app-dark2 !important;
    color: $base-white !important;
    border-radius: 2px;
  }

}

.kotler-text-field-label {
  font-size: 0.75rem;
  color: #636376;
  line-height: 24px;
  font-weight: 600;
}

.kotler-text-field-footer {
  font-weight: 500;
  font-size: 12px;
  color: $app-dark2;
  text-transform: uppercase;
  line-height: 24px;
  letter-spacing: 1px;
}

//white use in login
.kotler-text-field-white-form-control {
  label {
    font-size: 12px;
    color: $app-secondary-main;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 5px;
    margin-left: 2px;

    letter-spacing: 0px;
    line-height: 24px;
  }

}

.kotler-text-field-white {

  .MuiInputBase-formControl input {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;

    color: $app-secondary-main;
  }

  .MuiInputBase-formControl {
    background-color: #FFFFFF;
  }

  .MuiInputBase-formControl fieldset {
    border-radius: 2px;
    overflow: hidden;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $app-secondary-light !important;

  }

}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: $danger !important;
  color: $danger !important;
}

.MuiInputBase-formControl input::placeholder {
  color: $app-dark2;
  font-style: italic !important;
}