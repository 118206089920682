.kotler-radio-buttons-form-control {
  .MuiFormControlLabel-root {
    margin: 0;
    .MuiFormControlLabel-label {

      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #121212;

    }
  }

  .kotler-radio-buttons-principal-label {
    color: $app-dark !important;
    animation: none !important;
    position: relative;
    transform: none;
    font-weight: 500;
    font-size: 12px;

  }
}

.kotler-radio-button {
  color: $app-primary !important;
  padding: 0;

  .MuiIconButton-label div {
    padding: 0;
  }

  .MuiIconButton-label div>svg {
    fill: transparent;
    display: block;
    border: 1.5px solid $app-primary;
    border-radius: 100%;
    width: 24px;
    height: 24px;
  }

  .MuiIconButton-label div>svg+svg {
    fill: transparent;
    background-color: $app-primary2-light;
    width: 14px;
    margin: 5px;
    height: 14px;
    border: none;
  }
&.kotler-radio-slow{
  .MuiIconButton-label div>svg+svg {
  
    background-color: $danger;
    
  }
}
&.kotler-radio-medium{
  .MuiIconButton-label div>svg+svg {
  
    background-color: $warning;
    
  }
}

  &.Mui-disabled {
    .MuiIconButton-label div>svg {
      background-color: $app-light;

      border: none;
    }

  }
}