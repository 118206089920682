.session-toggle {
  gap: 3px !important;
  cursor: pointer;
  &:hover {
    opacity: 0.95;
  }
  width: auto;

  .session-input {
    font-size: 10px;

    background-color: transparent;
    outline: none !important;
    border: 0;
    margin: 3px 0;
    border-radius: 2px;
    &:hover {
      background-color: rgba($color: #fff, $alpha: 0.4);
    }
    &:focus {
      background-color: white;
    }
  }
}

.open {
  .session-toggle {
    background-color: var(--primary2-light);
  }
}
