.rc-tree-switcher {
  background-image: none !important;
}

.rc-tree li .rc-tree-node-content-wrapper {
  height: 25px !important;
}

.rc-tree-node-selected {
  background-color: unset !important;
  border: none !important;
  opacity:1 !important;
}
