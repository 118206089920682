.liveads-container {
  min-width: 300px;
  max-width: 300px;
  min-height: 500px;
  flex: 1;
  padding: 10px;

  color: #000;
  border-radius: 5px;
  background-color: rgba($color: #fff, $alpha: 0.2);

  overflow: hidden;
  position: relative;

  flex: 1;

  .liveads-wraper {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    > * {
      width: 100%;
      // background-color: rgba($color: #fff, $alpha: 0.8);
    }
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      margin-top: 10px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      box-shadow: 0 0 1px 1px #bbb, inset 0 0 7px rgba(0, 0, 0, 0);
    }
    &::-webkit-scrollbar-thumb {
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6)
      );
      border-radius: 15px;
      // box-shadow: inset 0 0 1px 1px $app-primary2;
    }
    &::-webkit-scrollbar-thumb:hover {
      cursor: pointer;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0.8)
      );
    }
  }
}
