.cbg-dark {
  background-color: $cdark;
  transition: all 0.3s ease-out
}

.cbg-blue {
  background-color: $cblue;
  transition: all 0.3s ease-out
}

.cbg-orange {
  background-color: $corange;
  transition: all 0.3s ease-out
}

.cbg-primary-dark {
  background-color: $cprimary-dark !important;
}

.bg-gray-1 {
  background-color: $gray-1 !important;
}

.bg-gray-3 {
  background-color: $gray-3 !important;
}

.text-gray-2 {
  color: $gray-2 !important;
}

.cbg-light {
  background-color: $lwhite;
  transition: all 0.2s ease-out
}
