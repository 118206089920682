.rdt {
  .form-control {
    box-shadow: none;
    background-color: transparent;
    height: unset;
  }

  .rdtDay.rdtActive {
    background-color: $app-primary;
  }

  .rdtDay.rdtToday:before {
    border-bottom-color: $app-primary
  }
}

.rc-time-picker {
  .rc-time-picker-input {
    box-shadow: none;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 0;
    height: 32px;
    font-size: 1rem;
    box-sizing: border-box;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: rgba(0, 0, 0, 0.87);
    padding: 0;

    &:focus {
      outline: none;
    }
  }

  &.Mui-error .rc-time-picker-input {
    border-bottom: 2px solid $danger;
  }

  .rc-time-picker-clear {
    bottom: 3px;
    top: unset;
    right: 0;
  }
}

.app-radio-button {
  padding: 4px !important;
}

.input-range {
  margin-top: calculateRem(40px);

  .input-range__slider {
    background: $app-primary;
    border-color: $app-primary;
  }

  &__track--active {
    background: $app-primary !important;
  }
}

.table-input-range {

  .input-range {
    margin-top: calculateRem(15px);
    margin-bottom: calculateRem(15px);
  }
}

.app-label-shrink {
  transform: translate(0, 1.5px) !important;
}

.label-font-sm {
  font-size: calculateRem(15px);
}

form .Mui-error {
  color: $danger
}

// V2
.MuiInputBase-formControl input {
  background: $white;
  border-radius: 5px;
}

.MuiFormControlLabel-label, .MuiFormControlLabel-label .label-font-sm {
  font-size: 14px !important;
}

.form-control-app-sm input {
  padding-top: 10.5px;
  padding-bottom: 10.5px;
}

.custom-select {
  &__menu {
    z-index: 10000 !important;
  }

  &-container.is-invalid {
    .custom-select__control {
      border: 1px solid $danger !important;
    }
  }

  &__single-value {
    color: currentColor;
    font-size: 1rem;
  }

  &__control {
    min-width: 80px;
    min-height: 40px !important;
    border-color: rgba(0, 0, 0, 0.23) !important;

    &:hover {
      border-color: rgba(0, 0, 0, 1) !important;
    }

    &--is-focused {
      border: 2px solid $app-primary !important;
      box-shadow: none !important;

      &:hover {
        border: 2px solid $app-primary !important;
      }
    }
  }

  &__indicator {
    padding: 5px !important;
  }
}

.dz-clickable {
  &:focus {
    outline: none !important;
  }
}
