.kotler-product-icon-container {
    height: 100%;
    width: 56px;
    padding: 0px 0px 0px 0px;
    min-height: 168px;

    .kotler-product-icon {
        background-color: $app-primary2-light-plus;
        width: 100%;
        height: 100%;

        padding: 6px 0px;
        display: flex;
        justify-content: center;

        img {
            width: 40px;
            height: 40px;
        }

        svg {
            width: 32px;
            height: 32px;

        }
    }
}