.tutorial-content-container {
  iframe {
    width: 100% !important;
    object-fit: cover;
    aspect-ratio: 16/9 !important;
    height: auto !important;
    object-fit: cover !important;
  }
  img {
    object-fit: contain;
    object-position: top;
    height: auto !important;
  }
}
