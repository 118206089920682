.kotler-tabs {
  min-height: 0px !important;

  padding: 0 15px;
  .MuiTab-labelIcon {
    min-height: 0px !important;
    padding: 0 0 5px 0;
  }

  .MuiTabs-flexContainer {
    gap: 15px;
  }

  .MuiTab-root {
    * {
      text-transform: none;
    }

    .MuiTab-wrapper {
      display: inline-block;
      vertical-align: middle;
      flex-wrap: nowrap;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      text-align: left;
      color: $app-dark;

      svg {
        margin: 0 10px;
        stroke: $app-dark !important;
      }
    }
    &.Mui-disabled {
      .MuiTab-wrapper {
        color: $app-dark2;
      }
      svg {
        stroke: $app-dark2 !important;
      }
    }
    &.Mui-selected {
      .MuiTab-wrapper {
        color: $app-primary;
      }

      svg {
        stroke: $app-primary !important;
      }
    }
  }
}
