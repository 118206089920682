.app-drop-down-session .session-toggle {
    justify-content: space-between;
    border-radius: 2px;
    min-height: 24px;
    background-color: $app-secondary-light;
    gap: 10px;
    padding: 0px 5px;
    display: flex;
    align-items: center;
    min-width: 100px;

}

.app-drop-down-session .session-toggle span {
    font-size: 10px;
    line-height: 175%;
    color: $app-text-dark  !important;
}

.app-drop-down-session .session-toggle svg {
    transition: transform .2s ease-in-out;
}

.app-drop-down-session .drop-down-menu{
    padding: 0 ;
    border-radius: 2px;
}
.app-drop-down-session .list-content {
    position: relative;
    overflow: auto;
    max-height: 500px;
    padding: 0;
  
}

.app-drop-down-session .list-item-button {
    max-height: 40px;
}

.app-drop-down-session .list-item-button:hover {
    background-color: $app-light;
}

.app-drop-down-session .list-item-text {
    color: $app-dark;
}