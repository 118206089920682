.bounce-infinite{

 animation: bounceIn 3s infinite alternate ;
}
   @keyframes bounceIn{
        0%{
        
          transform: scale(0.89) translate3d(0,0,0);
        }
        50%{
          
          transform: scale(1.4);
        }
        80%{
          transform: scale(0.89);
        }
        100%{
          transform: scale(1) translate3d(0,0,0);
        }
      }