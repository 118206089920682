.kotler-expansion-panel {



    .MuiExpansionPanelSummary-root {
        height: 40px;
        min-height: 0px !important;
        background: #EEEFEE;
        border: 1px solid $app-primary2;
        border-radius: 2px;
        border-radius: 2px;
        padding: 0px 16px 0px 16px;
        margin-bottom: 5px;

        .MuiExpansionPanelSummary-content {
          
            padding: 0;
            margin: 0;
        }
    }
    .MuiCollapse-container{
        .MuiExpansionPanelDetails-root{

            padding: 16px;
        }
        border: none;
        border-radius: 0;
        box-shadow: 0px 2px 4px 0px #333F48A3;

    }
    &::before {
        display: none;

    }
}