.kotler-pagination-container {
    width: 100%;
    .kotler-pagination-down-bar {
        .kotler-page-size-select {
            border: 1.5px solid $app-primary;
            padding: 2px 5px;
            border-radius: 2px;
            color: $app-primary;
            text-align: center;


            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            text-align: center;

            .MuiSelect-root {
                padding: 0 20px 0 0px;
            }

            &:hover {
                background-color: rgba($color: $app-primary, $alpha: 0.1);
            }

            .MuiSvgIcon-root {
                fill: $app-primary;
            }

            &::before,
            &::after {
                border: none !important;
            }
        }

        .container-actions-page {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            color: $app-dark;
            word-spacing: 5px;

            .action-button {
                border: 1.5px solid $app-primary;
                border-radius: 2px;
                padding: 0 !important;
                min-width: 0;
                width: 24px;
                height: 24px;
&.Mui-disabled{
    border: 1.5px solid $app-dark2;
    
    svg{
        stroke:$app-dark2 !important;
    }
}
                &:hover {
                    background-color: rgba($color: $app-primary, $alpha: 0.1);
                }
            }
        }

    }
}

.kotler-pagination-select {
  
    .MuiMenu-paper{
        .MuiListItem-root{
            &:hover{
                background-color: rgba($color: $app-primary2, $alpha: 0.1) ;
            }
        }
        .Mui-selected{
        background-color: transparent ;
        font-weight: 500;
        font-size: 14px;
        color:$app-primary2-light;
        
        }
    }
}