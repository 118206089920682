.thead-false th{
    background-color: #04a89e;
    color: #FFF;
    line-height: 1em;
    vertical-align:bottom;
}
.td-column-remove{
    background-color: white !important;
}
.download-btn{
position: absolute;
z-index: 1;
}
.container-first-table{
    margin-bottom: 20px;
}
.table-responsive-1{
    width: 100%;
}
.table-responsive-1 td:nth-child(4),.table-responsive-1 th:nth-child(4) ,.table-responsive-1 tr > *:nth-child(8){
    text-align: center;
  }
  .table-responsive-1 tr > *:nth-child(n+9){
    text-align: right;
  }
.container-second-table{
    display: none;
}
.table-responsive-2 tr *:nth-child(n+3){
    text-align: right;
}
@media (max-width:1149px){
.table-responsive-1 tr > *:nth-child(n+4) {
    display: none;
}
.table-responsive-1 tr > *:nth-child(13) {
    display: table-cell;
}
.table-responsive-1 .tr-responsive{
    display: none;
}
.container-second-table{
display: flex;
}
.container-first-table{
    margin-bottom: 0;
}
.td-column-remove{
    background-color: initial !important;
}
.display-none-responsive{
    display: none !important;
}
.download-btn{
    position: inherit;
    z-index: 1;
    }
}