.changelog-register-title {
  color: var(--dark);
  margin: 0;
  font-size: 18px;
  margin-left: 15px;
  font-weight: 500;
  line-height: 1.2em;

  color: var(--primary2);
}
.changelog-register-time {
  color: var(--dark);
  font-weight: 400;
  font-size: 13px;
  margin-left: 15px;
  color: var(--dark);
}

.changelog-register {
  margin-left: 10px;
  display: flex;
  min-height: 35px;
  align-items: center;
  h5 {
    font-size: 12px;
    line-height: 16px;

    color: var(--dark);
  }
}
.changelog-item {
  cursor: pointer !important;
  * {
    cursor: pointer !important;
  }
  &:hover {
    cursor: pointer;
    background-color: var(--light);
  }
}

.changelog-expand {
  border: none !important;
  .MuiCollapse-container {
    border: none !important;
  }
}
