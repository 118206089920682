.overflow-visible {
  overflow: visible !important;
}

.borderless {
  border: 0 !important;
}

.border-radius-0 {
  border-radius: 0 !important;
}
.thead-report {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.td-report {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.td-crm {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.crm-detail-bg-1 {
  background-color: rgba(0, 0, 0, 0.05);
}

.cursor-pointer {
  cursor: pointer;
}
