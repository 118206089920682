$prefix: manual-ai;
.#{$prefix}-input-container {
  background-color: var(--gray-dark);
  padding: 10px;
  display: flex;
  column-gap: 10px;
  border-radius: 14px;
  .#{$prefix}-input {
    border: 0;
    flex-grow: 1;
    border-radius: 5px;
    min-height: 30px;
    height: fit-content;
    padding: 5px;
    font-size: 12px;
    font-weight: 500;
    color: var(--gray-dark);
    background-color: white;
    &:focus {
      outline: none !important;
      border-color: #afe2d9;
      box-shadow: 0 0 5px 0px var(--gray-dark);
    }
  }
}
.#{$prefix}-btn-send {
  background-color: #afe2d9;
  border: 0;
  padding: 3px;
  border-radius: 5px;
  &:hover {
    background-color: var(--primary2-light);
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
    background-color: white;
  }
}
.container-response {
  position: absolute;
  overflow: hidden;
  display: flex;
  align-items: center;
  top: 100%;
  z-index: -1;
  min-height: 100%;
  pointer-events: none;
  border-radius: 5px;
  width: 100%;
  padding: 8px;
  @media (max-width: 767px) {
    justify-content: center;
    top: 100%;
    left: 0;

    margin-top: 16px;
  }
  @media (min-width: 768px) {
    min-width: 450px;
    left: 100%;
    padding-top: 0;
    margin-left: 8px;
    top: 0;
  }
  .drawer-container-response {
    pointer-events: auto;
    @media (max-width: 767px) {
      transform: translateY(-110%);
    }
    @media (min-width: 768px) {
      transform: translateX(-110%);
    }
  }
  &.show {
    .drawer-container-response {
      transition: transform 300ms ease-in-out;
      @media (max-width: 767px) {
        transform: translateY(0);
      }
      @media (min-width: 768px) {
        transform: translateX(0);
      }
    }
  }
}
