.kotler-card {
    border-radius: 0;
    border: none;
    border-left: 1px solid $app-dark2;
    padding: 3px 10px;
    margin-bottom: 0;

   

    .jr-card-body {
        padding: 0;
        
    }
}
.kotler-card-head {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin: 0;
}
.kotler-card-gray{
    background-color: $base-light-gray;
    
}