.kotler-diagram {
    .title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        display: flex;
        align-items: center;
        color: #121212;

    }

    .value  {

        background: #B3DFD9;

        border: 1px solid #009681;
        border-radius: 2px;
        font-variant: common-ligatures tabular-nums;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #121212;

    }
}