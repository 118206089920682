/*Login Styles*/
/*Login Styles*/
//kotler stiles -login- 
.app-login-container {
  .logo-login {
    width: 160px;

  }

  .intro-login-text {
    color: $app-dark !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .kotler-button-lg {
    padding: 2px 0px !important;
    min-width: 112px
  }
}

.btn-register-code {
  width: 35% !important;
  max-width: 130px !important;
  min-width: 80px !important;
}


.code-to-register-modal {
  padding: "200px";
}

//open - form code register
.login-modal-form .MuiInputBase-formControl,
.login-modal-form .MuiInputBase-formControl input {
  font-weight: 500;
  color: $app-dark;
  background: $app-light;
}

.login-modal-form .MuiOutlinedInput-notchedOutline {
  border-color: $app-dark2 !important;

}

//close - form code register
//kotler stiles -login- 


.login-content {
  width: 420px;
  margin: auto;
  padding: 35px 35px 20px;
  background-color: $white;
  @include box-shadow($shadow);
  @include border-radius(4px);
  font-size: 14px;
  max-width: 94%;
}


.login-content .form-control {
  background-color: map_get($gray, lighten-4);

  &:focus {
    box-shadow: none;
    border-color: $app-primary;
  }
}


.app-login-container {
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0 auto;

  & .loader-view {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 0;
    bottom: 0;
    z-index: 2;
  }
  @media screen and (max-width: 600px) {
    background-position: right;
    
    
  }
  @media screen and (
    min-width: 601px
  ) and (
    max-width: 768px) {
    background-position:  90%;
    
  }
  @media screen and (
    min-width: 769px
  ) and (
    max-width: 1000px) {
    background-position:  70%;
    
  }
}








/* 
.app-login-content .btn, .login-content .jr-btn {
  padding: 10px 35px !important;
}
 */
.app-logo-content {
  background-color: $app-primary;
  padding: 35px 35px 20px;
  width: 100%;
  order: 1;

  @media screen and (max-width: 575px) {
    width: 100%;
    order: 1;
  }
}

.app-social-block {
  @include display-flex();
  @include align-items(center);
  @include justify-content(space-between);

  & .social-link,
  & .social-link li {
    margin: 0;
  }

  & .social-link button {
    border: solid 1px $app-primary;
    @include border-radius(50%);
    color: $app-primary;
    padding: 0;

    &:hover,
    &:focus {
      color: $white;
      background-color: $app-primary;
    }
  }

  & p {
    margin-bottom: 0;
  }
}