.app-product-list {
  .app-product-item {
    @include align-items(center);
    @include display-flex();
    border-bottom: solid 1px $gray-200;
    padding: 8px 12px;
  }

  .app-product-name {
    font-size: 15px;
  }
}

.app-variable-list {

  .app-variable-item {
    border-top: solid 1px $gray-5;
    padding: 5px 16px;
    @include display-flex(flex, column, nowrap);
    @include justify-content(center);
    cursor: pointer;
    max-height: 96px;

    &.active,
    &:hover {
      background-color: $gray-1;
    }
  }

  .app-variable-desc {
    color: $gray-600;
    font-size: 13px;
  }
}

.table-products {
  thead td, thead th {
    cursor: default;
    border-top: 0;
    border-bottom: 0;
    font-size: 14px;
    line-height: 15px;
  }

  tbody {
    td {
      border-top: 0;
      vertical-align: middle;
    }
  }
}

.table-products-tablet {
  thead td, thead th {
    background-color: #fff;
  }
}

.table-products-laptop {
  thead td, thead th {
    background-color: #fff;
  }
}

.table-products-cell {
  thead td, thead th {
    background-color: #fff;
  }
}

.table-header {
  thead th {
    color: #333F48;
  }
}

.table-bundle {
  thead td, thead th {
    background-color: #fff;
  }
}

.badge-product-active {
  font-size: 14px;
  background-color: #09a99e;
  color: white;
  width: 86px;
}

.badge-product-ready {
  font-size: 14px;
  background-color: #F7C245;
  width: 86px;
}

.badge-product-canceled {
  font-size: 14px;
  background-color: #E35241;
  color: white;
  width: 86px;
}

.badge-product-rd {
  font-size: 14px;
  background-color: #9F9F9D;
  color: white;
  width: 86px;
}

.bg-product-tablet {
  background-color: #003594 !important;
  color: #fff !important;
}

.bg-product-laptop {
  background-color: #003594 !important;
  color: #fff !important;
}

.bg-product-cell {
  background-color: #003594 !important;
  color: #fff !important;
}

.bg-bundles {
  background-color: #003594 !important;
  color: #fff !important;
}

.bg-research {
  background-color: #003594 !important;
}

.bg-customer {
  background-color: #003594 !important;
  color: #fff !important;
}

.bg-crm {
  background-color: #b63101 !important;
  color: #fff !important;
}

.sizecustom {
  width: 100px !important;
  height: 50px !important;
  margin-right: 2px !important;
}
