@keyframes typing {
  0% {
    width: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}

$prefix: manual-ai;
.#{$prefix}-response-container {
  background-color: var(--gray-dark);
  padding: 10px;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  ::selection {
    background-color: rgba(255, 255, 255, 0.1);
  }
  white-space: pre-wrap;
}
// $prefix: manual-ai;
// .#{$prefix}-response-container {
//   background-color: transparent;

//   border-radius: 8px;
//   color: var(--dark);
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 16px;
//   white-space: pre-wrap;
// }

.ai-source {
  $aqua-1: #16c6b7;
  border: 1px solid $aqua-1;
  font-size: 12px;
  color: $aqua-1;
  font-weight: 400;
  overflow: hidden;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  .id-box {
    background-color: white;
    width: 18px;
    color: #5b5b5b;
    border-right: 1px solid $aqua-1;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    aspect-ratio: 1/1;
  }
  .link-box {
    text-decoration: underline $aqua-1;
    padding: 0 6px !important;
  }
  &:hover {
    text-decoration: none;
    color: $aqua-1;
    background-color: rgba(255, 255, 255, 0.2);
  }
}
