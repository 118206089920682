.kotler-button {
  text-transform: none !important;
  padding: 2.5px 15px !important;
  border-radius: 2px !important;

  .MuiButton-label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }

  &.kotler-button-danger {
    color: $danger;

  }


}

.kotler-button-sm {
  .MuiButton-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  text-transform: none !important;
  border-radius: 2px !important;
  min-height: 32px !important;
  min-width: 156px !important;
}

.kotler-button-md {
  .MuiButton-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  text-transform: none !important;
  border-radius: 2px !important;
  min-height: 40px;
}

.kotler-button-lg {
  .MuiButton-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  text-transform: none !important;
  padding: 2px 25px !important;
  border-radius: 2px !important;
  min-height: 32px;
}

.kotler-btn {
  .MuiButton-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  text-transform: none !important;
  border-radius: 2px !important;
  min-height: 32px;

}

.kotler-header-section-container-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;

  .MuiButtonBase-root {
    font-size: 0.87rem;
    text-transform: none;
    font-weight: 500;
    color: $base-white;
  }

  .kt-btn-submit {
    background-color: $app-primary;
    border-radius: 2px;

  }

  .MuiButtonBase-root:hover {
    transition: all ease-in-out 0.3s;
    filter: drop-shadow(0px 4px 8px rgba(51, 63, 72, 0.64));
  }

  .kt-btn-submit:hover {
    background-color: rgba($color: $app-primary, $alpha: 0.9);
  }

  .kt-btn-cancel {
    background-color: $danger;
    border-radius: 2px;
    min-width: 115px;
  }

  .kt-btn-cancel:hover {
    background-color: rgba($color: $danger, $alpha: 0.8);
  }

  .kotler-badge {
    background-color: $app-primary2-light;
    font-size: 0.7rem;
    padding: 4px 8px;
    color: $app-dark;
    margin: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
  }

}

.hr-button {
  border-radius: 1px !important;

  .MuiButton-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-transform: none;
  }
}