.kotler-slider {
  margin-bottom: 30px;
  .MuiSlider-thumb {
    background-color: #dddfdd;
    margin: 0 0;
    margin-left: -1.4px;
    border: 1.6px solid $app-dark;
  }
  .MuiSlider-valueLabel {
    bottom: -45px;
    margin-left: -3px;



    top: auto;
    * {
      background-color: transparent;
      font-size: 12px;
font-weight: 400;
line-height: 20px;
      color:$app-dark;
    
    }
  }
  .MuiSlider-thumb::before {
    background-color: $app-dark;
    content: "";
    border-radius: 4px;
    width: 4px;
    height: 4px;
    visibility: visible;
  }

  .MuiSlider-track {
    background-color: $app-primary2-light;
    height: 8px;
    margin-top: 2px;
    border-radius: 10px 0 0 10px;
  }

  .MuiSlider-rail {
    height: 8px;
    margin-top: 2px;
    width: calc(100% + 10px);
    border-radius: 10px;
    opacity: 1;
    background-color: #dddfdd;
  }

  .MuiSlider-markLabel {
    margin-left: 4px !important;
    margin-top: 3px !important;
  }

  .MuiSlider-mark {
    display: none;
    margin-left: 4px !important;
  }
}

//disabled
.kotler-slider.Mui-disabled {
  .MuiSlider-thumb {
    margin: 0 0;
    margin-left: -1.4px;
    width: 12px;
    height: 12px;
    border: 1.6px solid $app-dark2;
  }

  .MuiSlider-thumb::before {
    background-color: $app-dark2;
    content: "";
    border-radius: 4px;
    width: 4px;
    height: 4px;
    visibility: visible;
  }

  .MuiSlider-track {
    background-color: $app-dark2;
  }

  
}