.box-code-container {
  box-shadow: 2px 2px 4px 3px rgba($color: #000, $alpha: 0.3);

  padding: 15px;
}
.text-area-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  letter-spacing: 1px;
  background-color: #5b656d;
  overflow: auto;

  .pre-html {
    color: var(--primary2-light);
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    background-color: #5b656d;
    left: 5px;
    right: 25px;
    &.pre-html-top {
      top: 0;
      padding-bottom: 3px;
    }
    &.pre-html-bottom {
      bottom: 0;
      padding-bottom: 3px;
    }
  }
  .text-area-code {
    min-height: 400px;
    letter-spacing: 1px;
    white-space: pre-wrap;
    padding: 25px 30px;
    color: rgba($color: #fff, $alpha: 0.8);
    padding-left: 30px;
    height: fit-content;

    outline: none;
    flex-grow: 1;
    background-color: transparent;
    max-height: 600px;
    &::placeholder {
      color: rgba($color: #fff, $alpha: 0.5);
    }
    box-sizing: border-box;
  }
}
