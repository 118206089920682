.kotler-check-box-form-control {
    .MuiFormControlLabel-label {

        font-weight: 400;
        font-size: 14px;
        color: #121212;
    }

    .kotler-check-boxes-principal-label {
        color: $app-dark !important;
        animation: none !important;
        position: relative;
        transform: none;
        font-weight: 500;
        font-size: 12px;

    }
}

.kotler-check-box {
    
    &.MuiCheckbox-root {
        color: transparent;
        margin-left: 3px !important;
        &:hover {
            border-radius: 2px;
        }
    }

    .MuiIconButton-label {
        border: 1px solid $app-primary;
        margin-left: 0px;
        border-radius: 2px;
    }

    &.Mui-checked {
        .MuiIconButton-label {
            background-color: $app-primary;
        }
    }

    .MuiTouchRipple-root {

        border-radius: 2px !important;

        * {
            border-radius: 2px !important;
        }
    }
}