.closeButton {
  position: absolute;
  right: 1;
  top: 1;
}
.profile-picture {
  border-radius: 100%;
  color: #fafafa;
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 0;
  flex: 0 0 200px;
  height: 200px;
  -webkit-box-pack: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 200px;
}

.img__overlay {
  -webkit-box-align: center;
  align-items: center;
  bottom: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  z-index: 1;
  background-color: rgba(46, 204, 113, 0.4);
  background: linear-gradient(
    65deg,
    rgba(46, 204, 113, 0.4),
    rgba(243, 156, 18, 0.4)
  );
  color: #fafafa;
  font-size: 24px;
}

.img__overlay--green {
  background: linear-gradient(
    65deg,
    rgba(46, 204, 113, 0.4),
    rgba(102, 51, 153, 0.4) 60%
  );
  background-color: rgba(46, 204, 113, 0.4);
}

.img__overlay:hover {
  opacity: 1;
  cursor: pointer;
}