.kotler-tab-pills{
    min-height: 0px !important;

    
    padding: 0 15px;
    .MuiTab-labelIcon {
        min-height: 0px !important;
       
    }

    .MuiTabs-flexContainer {
        gap: 15px;
    }
 .MuiTabs-indicator{
    border-radius: 20px;
    background-color:rgba($color: $app-primary2, $alpha: .1) ;
    height: 100%;
 }

    .MuiTab-root {
border: 1px solid $app-primary2;
padding: 0 20px;
border-radius: 20px;
min-height: 30px !important;
max-width: 300px;

        * {
            text-transform: none;
        }

        .MuiTab-wrapper {
            display: inline-block;
            vertical-align: middle;
            flex-wrap: nowrap;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
            color: $app-dark;

            svg {
                margin: 0 10px;
                stroke: $app-dark !important;
            }
        }

        &.Mui-selected {/* 
            background-color: $app-primary2; */
            .MuiTab-wrapper {
                color: $app-primary2;
font-weight: 500;
            }

            svg {
                stroke: $base-white!important;
            }
        }

    }
}