.kotler-big-calendar {
    .rbc-toolbar {
display: flex;
flex-wrap: wrap;
flex-direction: row-reverse;

        .rbc-btn-group {
           

            button {
                border: 1px solid rgba($color: $app-primary2, $alpha: 054);
                font-weight: 600;
                font-size: 0.75rem;
                text-transform: none;
                color: $app-primary2;
            }

            button:hover {
                background-color: $app-secondary-light;
            }

            button:focus {
                background-color: $app-primary2-light-plus;
            }


        }

        .rbc-toolbar-label {
            margin-top: 5px;
            font-size: 18px;
            min-width: 175px;
            text-transform: capitalize;
            font-weight: 600;
            color: $app-dark;
            text-decoration: underline $app-primary2-light 2px;

        }
    }

    .rbc-month-view {
        border-radius: 10px;
        overflow: hidden;
        
        border-color:  $app-primary2;
        .rbc-month-header{
            border-color: $app-primary2;
            background-color: $app-primary2;
            color:$base-white;
            .rbc-header{
                border-color: $app-primary2;
            background-color: $app-primary2;
            }
        }
        .rbc-month-row{
            background-color: $base-white;
            border-color: $app-primary2;
            .rbc-day-bg{
           border-color: $app-primary2;
            }
        }
        .rbc-row{
            .rbc-date-cell{
                display: flex;
                align-items: center;
                justify-content: center;
                a{
                    font-size: 18px;
                }
                &.rbc-off-range{
                    color:rgba($color: $app-primary2, $alpha: .3)
                }
                &.rbc-now{
                    color:rgba($color: $app-primary2, $alpha: 1);
                }
            }
          
        }
        
    }
    .rbc-off-range-bg{
        background-color: rgba($color: $app-primary2, $alpha: .2);
        
    }
    .rbc-today{
background-color: $app-primary2-light-plus;
    }
    .rbc-row-segment{
        padding: 0px 3px;
    }
}